@font-face {
  font-family: "CenturyOldStyle";
  font-display: fallback;

  /* 
  src: 사용할 폰트의 위치 및 형식 
     Safari, Android, iOS => ttf
     Modern Browsers => woff
     IE6-IE8 => eot
  */
  src: url("./CenturyOldStyleStd-Regular.ttf") format("truetype"), url("./CenturyOldStyleStd-Regular.eot?iefix") format("embedded-opentype");

  /* 사용할 폰트의 기본 스타일 */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "TuskerGrotesk-6600";
  font-display: fallback;

  src: url("./TuskerGrotesk-6600Semibold.ttf") format("truetype"), url("./TuskerGrotesk-6600Semibold.eot?iefix") format("embedded-opentype");

  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "TuskerGrotesk-4500";
  font-display: fallback;

  src: url("./TuskerGrotesk-4500Medium.ttf") format("truetype"), url("./TuskerGrotesk-4500Medium.eot?iefix") format("embedded-opentype");

  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "BERNIERRegular";
  font-display: fallback;

  src: url("./BERNIERRegular-Regular.ttf") format("truetype"), url("./BERNIERRegular-Regular.eot?iefix") format("embedded-opentype");

  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "JJGothic-Medium";
  font-display: fallback;

  src: url("./JJGothic-Medium.ttf") format("truetype"), url("./JJGothic-Medium.eot?iefix") format("embedded-opentype");

  font-weight: 400;
  font-style: normal;
}